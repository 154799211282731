import React, { useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

import { initiateUserAuth } from 'common/auth/modalLogin';
import { runPropTypes, userPropTypes } from 'common/propTypes';
import {
  deleteRun, onRunForceExit, onStopQuant, updateRun
} from 'common/api/run';
import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import WebShare from 'common/components/WebShare';
import moment from 'moment';
import { getUrlParams } from 'common/utils/urlUtils';
import ViewRunConfigs from 'v2/ui/run/RunShow/ViewRunConfigs/index';
import DeployLiveRun from 'v2/ui/run/RunShow/DeployLiveRun/index';
import HoverMessage from 'common/components/HoverMessage';
import { RUN_FINISHED_STATUSES } from 'common/configs/runStatus';
import SocialShare from './SocialShare';
import RunName from './RunName';
import ShowcasedRunTag from './ShowcasedRunTag';
import StopRunbtn from './StopRunbtn';
import CloneRunBtn from './CloneRunBtn';
import StarRunBtn from './StarRunBtn';
import DeleteRunBtn from './DeleteRunBtn';
import StrategyCreateForm from './StrategyCreateForm/index';
import EditRun from './EditRun';
import MultiplierTag from './MultiplierTag';
import ForceExitBtn from './ForceExitBtn';
import MarketPlaceAuthorTag from './MarketPlaceAuthorTag';
import ReStartButton from './ReStartButton';

const propTypes = {
  run: runPropTypes.isRequired,
  currentUserDefaultFolderId: PropTypes.number,
  currentUser: userPropTypes,
  strategyDetails: PropTypes.shape({}).isRequired,
  instrumentGroups: PropTypes.arrayOf(PropTypes.array),
};

const defaultProps = {
  currentUserDefaultFolderId: null,
  currentUser: null,
  instrumentGroups: []
};

const RunTitleAndActions = ({
  run: propsRun, currentUser, currentUserDefaultFolderId,
  strategyDetails, instrumentGroups
}) => {
  const [run, setRun] = useState(propsRun);

  const {
    id: runId, user_id: userId, name, folder_id: folderId, is_starred: isStarred, is_showcased: isShowcased,
    status, run_type: runType, display_name: runDisplayName,
    is_public: isPublic, quant_config: quantConfig,
    multiplier, order_type: orderType, title, start_date: startDate, end_date: endDate, summary,
    created_at: createdAt, is_strategy_subscriber: isMarketPlaceSubscriberRun,
    is_strategy_author: isMarketPlaceAuthorRun,
    is_individual_deployed_run: isIndividualDeployedRun, isHiddenSignal
  } = run;

  const { isUserSignedIn } = window;
  const { automatic: { value: automatic } } = ORDER_TYPES;
  const { publishInMarketPlace } = window.userPermissions;
  const isEnabled = _.get(publishInMarketPlace, 'isEnabled', false);

  const oneIndentation = ' ';
  const profit = _.get(summary, 'profit', 0);
  const currentUserId = _.get(currentUser, 'id', null);
  const isApproved = _.get(strategyDetails, 'is_approved', null);
  const isEmptyQuantConfig = _.isEmpty(quantConfig);
  const isRunBelongsToUser = currentUserId === userId;
  const { applicationDomain } = window;
  const host = _.get(applicationDomain, 'host', FRONTEND_PUBLIC_URL);
  let encodedUrl = encodeURI(`${host}/runs/${runId}?locale=en`);
  const urlParams = getUrlParams();
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
  if (isMinifiedPage) { encodedUrl += '&minifiedpage=true'; }
  // for paytm share also minified page
  const isLiveRun = runType === RUN_TYPES.live;
  const isShowAddToMarketPlaceButton = isLiveRun && orderType === automatic;
  const isShowMarketPlaceForm = isEnabled && isRunBelongsToUser
    && isShowAddToMarketPlaceButton && !isEmptyQuantConfig;
  const isShowEditRun = isRunBelongsToUser && isLiveRun;
  const isValidToHide = !_.includes(RUN_FINISHED_STATUSES, status);
  const isShowRunBelongsToButtons = (isMarketPlaceAuthorRun || isIndividualDeployedRun);

  const onDeleteRun = () => { deleteRun({ runId: run.id }); };

  const onStopRun = () => { onStopQuant(runId); };

  const onForceExit = (isReload = true, successCallback = () => { }) => {
    onRunForceExit(run.id).then(() => {
      reactToastify('Manual exit completed successfully', TOASTIFY_TYPES.SUCCESS);
      successCallback();
      if (isReload) setTimeout(() => window.location.reload(), 1000);
    }).catch(() => {
      reactToastify('Something went wrong, please try again', TOASTIFY_TYPES.ERROR);
    });
  };

  const onUpdateRun = (key, value) => {
    updateRun({ runId, run: { [key]: value } }).then(() => setRun({ ...run, [key]: value }));
  };

  const onCloneRun = () => {
    const qsConfig = { clone_from_run_id: runId, locale: I18n.locale };
    const cloneParams = qs.stringify(qsConfig);
    const cloneFolderId = currentUserId === userId ? folderId : '';

    if (isUserSignedIn) {
      window.location.href = `/runs/new?folder_id=${cloneFolderId}&${cloneParams}`;
      return;
    }

    initiateUserAuth().then(() => {
      window.location.href = `/runs/new?folder_id=${cloneFolderId}&${cloneParams}`;
    }).catch(() => {
      reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR);
    });
  };

  const onEditRun = () => {
    const qsConfig = { edit_run_id: runId, locale: I18n.locale };
    const editParams = qs.stringify(qsConfig);
    const editFolderId = currentUserId === userId ? folderId : '';

    if (isUserSignedIn) {
      window.location.href = `/runs/${runId}/edit?folder_id=${editFolderId}&${editParams}`;
      return;
    }

    initiateUserAuth().then(() => {
      window.location.href = `/runs/${runId}/edit?folder_id=${editFolderId}&${editParams}`;
    }).catch(() => {
      reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR);
    });
  };

  let text = '';
  if (runType === RUN_TYPES.historic) {
    text = `Backtest from ${startDate} to ${endDate} and Profit of Rs. ${profit}.`;
  } else {
    text = `${_.startCase(orderType)} Live run from ${moment(createdAt).format('YYYY-MM-DD hh:mm A')}`;
    text += ` and Profit of Rs. ${profit}.`;
  }

  return (
    <div className="page-heading">
      <div className="runshow-title">
        {!isIndividualDeployedRun && <i className="material-icons-marketplace mr-2" />}
        <RunName
          name={name || runDisplayName}
          isRunBelongsToUser={isRunBelongsToUser}
          isHideEditRun={!!isMarketPlaceSubscriberRun}
          onNameChange={(newName) => onUpdateRun('name', newName)}
        />
      </div>

      <div className="run-display-action">
        <ShowcasedRunTag isShowcased={isShowcased} isRunBelongsToUser={isRunBelongsToUser} />
        <MultiplierTag multiplier={multiplier} />
        {isMarketPlaceAuthorRun && <MarketPlaceAuthorTag run={run} />}
        <StopRunbtn
          status={status}
          isLiveRun={isLiveRun}
          onStopRun={onStopRun}
          isRunBelongsToUser={isRunBelongsToUser}
        />
        <ReStartButton
          instrumentGroups={instrumentGroups}
          run={run}
          isLiveRun={isLiveRun}
          isRunBelongsToUser={isRunBelongsToUser}
        />
        {isShowMarketPlaceForm && <StrategyCreateForm run={run} isApproved={isApproved} />}
        <ForceExitBtn
          status={status}
          isLiveRun={isLiveRun}
          onForceExit={onForceExit}
          isRunBelongsToUser={isRunBelongsToUser}
        />
        {isShowRunBelongsToButtons && (
          <>
            <DeployLiveRun
              run={run}
              currentUserDefaultFolderId={currentUserDefaultFolderId}
              isRunBelongsToUser={isRunBelongsToUser}
              shouldRenderDeployBtn={isRunBelongsToUser || (!!currentUserId && isShowcased)}
              currentUser={currentUser}
            />
            <ViewRunConfigs run={run} />
            <CloneRunBtn onCloneRun={onCloneRun} />
            {isShowEditRun && (
              isValidToHide ? (
                <HoverMessage
                  title="Enable Edit"
                  message="To edit this run, please stop the run first."
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary text-nowrap copy-btn track"
                    id="run-show"
                    name="edit"
                    disabled
                  >
                    Edit Run
                  </button>
                </HoverMessage>
              ) : (
                <EditRun onEditRun={onEditRun} />
              )
            )}
            <SocialShare
              isPublicRun={isPublic}
              runId={runId}
              isHiddenSignal={isHiddenSignal}
              isBelongsToCurrentUser={isRunBelongsToUser}
              onPublicRunChange={() => onUpdateRun('is_public', !isPublic)}
            />
            <StarRunBtn
              isStarred={isStarred}
              isRunBelongsToUser={isRunBelongsToUser}
              onStarRunChange={() => onUpdateRun('is_starred', !isStarred)}
            />
          </>
        )}
        <DeleteRunBtn
          isRunBelongsToUser={isRunBelongsToUser}
          onDeleteRun={onDeleteRun}
          status={status}
          isMarketPlaceAuthorRun={isMarketPlaceAuthorRun}
        />
        {isMarketPlaceSubscriberRun && (
          <WebShare url={encodedUrl} title={title} text={text} successCallBack={() => { }}>
            <button type="button" className="btn btn-outline-primary btn-sm d-flex gap-5 btn-pill">
              <i className="material-icons-outlined tx-18">share</i>
              {oneIndentation}
              Share
            </button>
          </WebShare>
        )}
      </div>
    </div>
  );
};

RunTitleAndActions.propTypes = propTypes;
RunTitleAndActions.defaultProps = defaultProps;

export default RunTitleAndActions;
