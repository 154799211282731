import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { INTENT_TYPES } from 'v2/common/constants/index';
import { useIntent } from './index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { getQuantModes } from '../../configs/index';

export const useBasketIntent = (intent) => {
  const {
    createOnChangeHandler,
    intentId,
    segment,
    onChangeSegment,
    onChangeIntentType,
    segmentOptionConfigs,
    isShowInstrumentIndex
  } = useIntent(intent);

  const mode = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode')
  ) || getQuantModes.simple.value;
  const isAdvancedMode = useMemo(() => mode === getQuantModes.advanced.value, [mode]);

  return {
    name: intent?.name || '',
    onChangeBasketName: createOnChangeHandler('name'),
    multiplier: intent?.multiplier,
    onChangeMultiplier: createOnChangeHandler('multiplier'),
    onChangeIntentType,
    intentId,
    segment: segment || INTENT_TYPES.OpenBasket,
    onChangeSegment,
    segmentOptionConfigs,
    isShowInstrumentIndex,
    isAdvancedMode
  };
};
