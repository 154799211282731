const getUpdatedIntents = (intents, newQuantity) => {
  return _.map(intents, (intent) => {
    if (!intent?.quantity) return intent;

    return {
      ...intent,
      quantity: newQuantity,
    };
  });
};

export const onResetQuantity = (cases, pipeConfigs, quantityValue) => {
  const updatedCases = _.map(cases, (caseConfig) => ({
    ...caseConfig,
    entry: {
      ...caseConfig.entry,
      intents:
        getUpdatedIntents(caseConfig?.entry?.intents, quantityValue) || [],
    },
    adjustments: _.map(caseConfig?.adjustments, (adjustment) => ({
      ...adjustment,
      intents: getUpdatedIntents(adjustment?.intents, quantityValue) || [],
    })),
  }));

  const updatedPipeConfigs = _.map(pipeConfigs, (pipeConfig) => {
    if (_.isEmpty(pipeConfig?.config?.legs)) return pipeConfig;

    return {
      ...pipeConfig,
      config: {
        ...pipeConfig.config,
        legs: getUpdatedIntents(pipeConfig?.config?.legs, quantityValue) || [],
      },
    };
  });

  return { updatedCases, updatedPipeConfigs };
};
