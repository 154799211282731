import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SegmentSelector from 'v2/common/components/SegmentSelector/index';
import Quantity from 'common/components/Quantity';
import WaitAndTrade from 'v2/common/components/WaitAndTrade/index';
import BuyOrSellSelector from 'v2/common/components/BuyOrSellSelector';
import StopsInput from 'v2/common/components/StopsInput/index';
import ReEntryOrExecute from 'v2/common/components/ReEntryOrExecute/index';
import { useOpenIntent } from 'v2/modules/withRunForm/hooks/useIntent/useOpenIntent';
import { intentPropTypes } from 'common/propTypes';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';
import classNames from 'classnames';
import { SL_TRAIL_TYPES } from 'v2/common/constants/index';
import TrailingStopLoss from 'v2/common/components/TrailingStopLoss/index';
import DelayBy from 'v2/common/components/StopsInput/DelayBy';
import InstrumentBasedDetails from './InstrumentBasedDetails';
import QuickAdjustments from './QuickAdjustments/index';
import IntentTypeSelector from '../IntentTypeSelector';

const propTypes = {
  intent: intentPropTypes.isRequired,
  renderDeleteBtn: PropTypes.element,
};

const defaultProps = {
  renderDeleteBtn: null,
};

const OpenLegIntent = ({
  intent, renderDeleteBtn
}) => {
  const intentConfigs = useOpenIntent(intent);
  const {
    control,
    errors,
    isAdjustmentIntent,
  } = useContext(IntentsContext);

  const parentDivClassName = classNames('leg-label-ribbon');

  const stopLossOrigin = intentConfigs?.stopLoss?.origin?.type || 'self';
  const stopGainOrigin = intentConfigs?.stopGain?.origin?.type || 'self';

  return (
    <>
      <span className={parentDivClassName}>
        <div className="wrapper">
          <div className="badge">
            Leg
            {' '}
            {intentConfigs?.intentId}
          </div>
        </div>
      </span>
      <div className="transaction_details body">
        <IntentTypeSelector
          isAdjustmentIntent={isAdjustmentIntent}
          isAdvancedMode={intentConfigs.isAdvancedMode}
          isIdle={intent.isIdle}
          value={intent.type}
          onChange={intentConfigs.onChangeIntentType}
        />
        <div className="transaction_details-items segment">
          <SegmentSelector
            segment={intentConfigs?.segment}
            segmentOptionConfigs={intentConfigs?.segmentOptionConfigs}
            onChangeSegment={intentConfigs?.onChangeSegment}
          />
        </div>
        <InstrumentBasedDetails intentConfigs={intentConfigs} />
        <div className="transaction_details-items quantity">
          <Quantity
            quantity={intentConfigs?.quantity}
            onChangeQuantity={intentConfigs.onChangeQuantity}
            control={control}
            errors={errors}
          />
        </div>
        <div className="transaction_details-items action_type">
          <BuyOrSellSelector side={intentConfigs?.side} onChangeSide={intentConfigs.onChangeSide} />
        </div>
        <div
          className={`transaction_details-items stop_loss ${stopLossOrigin !== 'self' ? 'elaborate' : ''}`}
        >
          <StopsInput
            stopsType={intentConfigs.stopLoss?.type}
            origin={stopLossOrigin}
            stopsValue={intentConfigs.stopLoss?.value}
            onStopsValueChange={intentConfigs.onChangeStopLoss}
          />
        </div>
        {intentConfigs.isAdvancedMode ? (
          <div className="transaction_details-items stop_loss_delay_by">
            <DelayBy
              delayBy={intentConfigs.stopLoss?.delayBy}
              onChange={intentConfigs.onChangeStopLossDelayBy}
            />
          </div>
        ) : null}
        <div
          className={`transaction_details-items take_profit ${stopGainOrigin !== 'self' ? 'elaborate' : ''}`}
        >
          <StopsInput
            stopsType={intentConfigs.stopGain?.type}
            stopsValue={intentConfigs.stopGain?.value}
            origin={stopGainOrigin}
            onStopsValueChange={intentConfigs.onChangeStopGain}
          />
        </div>
        {intentConfigs.isTrailingStopLossEnabled ? (
          <div
            className={
              `transaction_details-items trailing_stop_loss 
                ${intentConfigs.trailingConfigs.type === SL_TRAIL_TYPES.BookAndLinear
                ? 'tsl_book_and_linear'
                : ''
              }`
            }
          >
            <TrailingStopLoss
              isStopLossPresent={intentConfigs.isStopLossPresent}
              trailingConfigs={intentConfigs.trailingConfigs}
              onUpdateTrailingConfigs={intentConfigs.onUpdateTrailingConfigs}
              stopLossType={intentConfigs.stopLoss?.type}
            />
          </div>
        ) : null}
        {intentConfigs.isWaitAndTradeEnabled ? (
          <div className="transaction_details-items wait_and_trade">
            <WaitAndTrade
              waitAndTradeConfigs={intentConfigs.waitAndTradeConfigs}
              onChangeWaitAndTradeConfigs={intentConfigs.onChangeWaitAndTradeConfigs}
            />
          </div>
        ) : null}
        {_.some(intentConfigs.reEntryOrExecuteOptionConfigs, 'isShow')
          && (
            <div className="transaction_details-items re_entry_execute">
              <ReEntryOrExecute
                isDisabled={!intentConfigs?.isStopLossPresent}
                reEntryOrExecuteOptionConfigs={intentConfigs.reEntryOrExecuteOptionConfigs}
                onChangeReEntryorExecute={intentConfigs.onChangeReEntryorExecute}
              />
            </div>
          )}
        <QuickAdjustments
          intentId={intentConfigs?.intentId}
          quickAdjustments={intent?.uiConfigs?.quickAdjustments}
          onUpdate={intentConfigs.onUpdateQuickAdjustments}
        />
        <div className="transaction_details-items delete">
          {renderDeleteBtn}
        </div>
      </div>
    </>
  );
};

OpenLegIntent.propTypes = propTypes;
OpenLegIntent.defaultProps = defaultProps;

export default OpenLegIntent;
