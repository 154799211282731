import React, { useState } from 'react';
import { runPropTypes } from 'common/propTypes';
import Modal from 'common/components/ModalComponent';
import { useForm, FormProvider } from 'react-hook-form';
import { onCreateNewStrategy } from 'common/api/strategies';
import moment from 'moment';
import {
  // PURCHASE_TYPES,
  RISK_CATRGORIES, SEGMENT_NAMES
} from 'common/constants/index';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SuccessFailureMessageModal from 'common/components/SuccessFailureMessageModal';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { defaultStrategyFormConfig, getColumnDetails, getPurchaseDetails } from './configs';
import StrategyFormDescription from './StrategyFormDescription';
import StrategyInput from './StrategyInput';
import StrategyFormTags from './StrategyFormTags';

const propTypes = { run: runPropTypes.isRequired, isApproved: PropTypes.bool };
const defaultProps = { isApproved: null };

const StrategyCreateForm = ({ run, isApproved }) => {
  const { created_at: createdAt } = run;
  const [shouldShowModal, setShowModal] = useState(false);
  const [isApprovedAccess, setIsApprovedAccess] = useState(isApproved);
  const [strategyConfig, setStrategyConfig] = useState(defaultStrategyFormConfig(run));
  const [isShowSuccessModal, setIsSuccessModal] = useState(false);
  const oneIndentation = ' ';
  let title = '';
  if (isApprovedAccess === null) title = 'Add to Sample Strategies';
  if (isApprovedAccess === false) title = 'Sample Strategies Request Sent';
  if (isApprovedAccess) title = 'Request Granted';

  const methods = useForm();
  const onSubmit = () => {
    onCreateNewStrategy({ ...strategyConfig, tags: JSON.stringify(strategyConfig.tags) })
      .then(() => {
        setIsApprovedAccess(false);
        setShowModal(false);
        setIsSuccessModal(true);
      }).catch((errorResults) => {
        errorResults.json().then(({ errors }) => {
          _.each(errors, (error, key) => {
            reactToastify(`${_.startCase(key)} - ${_.join(error, ', ')}`, TOASTIFY_TYPES.ERROR);
          });
        });
      });
  };

  const renderColumnDetails = (detail, idx) => {
    const {
      label, strategyKey, value, className
    } = detail;
    const isShow = _.get(detail, 'isShow', true);
    if (!isShow) return null;

    return (
      <div className={className} key={idx}>
        <StrategyInput
          strategyKey={strategyKey}
          value={value}
          strategyConfig={strategyConfig}
          onUpdateStrategyConfig={setStrategyConfig}
          label={label}
        />
      </div>
    );
  };

  const renderOptions = (option, idx) => {
    const { label, value } = option;

    return <option value={value} key={idx}>{label}</option>;
  };

  const renderSegmentTypes = (segment, idx) => {
    const { displayName, value } = segment;
    const isChecked = value === strategyConfig.segment_type;
    const labelClassName = classNames('btn-lightness btn btn-sm btn-outline-primary', { active: isChecked });

    return (
      <label className={labelClassName} key={idx}>
        <input
          type="radio"
          name={value}
          id={value}
          autoComplete="off"
          value={strategyConfig.segment_type}
          checked={isChecked}
          onChange={() => { }}
          onClick={() => { setStrategyConfig({ ...strategyConfig, segment_type: value }); }}
        />
        {displayName}
      </label>
    );
  };

  // const renderPurchaseTypes = (type, idx) => {
  //   const { label, value } = type;
  //   const isChecked = value === strategyConfig.purchase_type;
  // const labelClassName = classNames('btn-lightness btn btn-sm btn-outline-primary',
  //   { active: isChecked }
  // );

  //   return (
  //     <label className={labelClassName} key={idx}>
  //       <input
  //         type="radio"
  //         name={value}
  //         id={value}
  //         autoComplete="off"
  //         value={strategyConfig.purchase_type}
  //         checked={isChecked}
  //         onChange={() => { }}
  //         onClick={() => { setStrategyConfig({ ...strategyConfig, purchase_type: value }); }}
  //       />
  //       {label}
  //     </label>
  //   );
  // };

  const renderSuccessMessage = () => {
    return (
      <div className="text-center">
        <div className="alert-icons icon-success alert-success" />
        <h4 className="font-weight-medium mb-3">Sample Request sent</h4>

        Your strategy has been created and is pending approval.
        Usually we will verify and approve the
        {' '}
        <span className="font-weight-medium">Strategy in 1 or 2 working days</span>
        .
        <div className="mt-2 font-weight-semi"> For more information contact us</div>
        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&to=support@quantman.in"
          target="_blank"
          rel="noreferrer"
        >
          <b>support@quantman.in</b>
        </a>
      </div>
    );
  };

  const renderSuccessModal = () => {
    if (!isShowSuccessModal) return null;

    return (
      <SuccessFailureMessageModal
        message={renderSuccessMessage()}
        onCloseModal={() => setIsSuccessModal(false)}
      />
    );
  };

  const columnDetails = getColumnDetails(strategyConfig);
  const purchaseDetails = getPurchaseDetails(strategyConfig);

  return (
    <>
      <button
        className="btn btn-sm btn-primary text-nowrap marketplace-action-btn"
        onClick={() => setShowModal(true)}
        type="button"
        disabled={isApprovedAccess !== null}
      >
        {title}
      </button>
      {shouldShowModal
        && (
          <Modal
            onClose={() => setShowModal(false)}
            title={title}
            okBtnTitle="Submit"
            onSave={methods.handleSubmit(() => onSubmit())}
            size="lg"
          >
            <FormProvider {...methods}>
              <h5 className="mb-1">{_.startCase(strategyConfig.title)}</h5>
              <div className="tx-12 text-muted">
                <span>Created at :</span>
                {oneIndentation}
                <b>{moment(createdAt).format('YYYY-MM-DD')}</b>
              </div>
              <hr />
              <label className="col-form-label col-form-label-sm">Strategy Title</label>
              <input
                type="string"
                className="form-control form-control-sm"
                id="title"
                name="title"
                value={strategyConfig.title}
                placeholder="Strategy Title"
                onChange={(event) => {
                  const { target: { value: targetValue } } = event;
                  setStrategyConfig({ ...strategyConfig, title: targetValue });
                }}
              />
              <br />

              <StrategyFormDescription
                strategyConfig={strategyConfig}
                onUpdateStrategyConfig={setStrategyConfig}
              />

              <div className="form-row">
                {_.map(columnDetails, renderColumnDetails)}
                <div className="form-group col-md-3">
                  <label className="col-form-label col-form-label-sm" htmlFor="">Risk Category</label>
                  <select
                    className="custom-select custom-select-sm"
                    id="risk_category"
                    value={strategyConfig.risk_category}
                    onChange={(event) => {
                      const { target: { value } } = event;
                      setStrategyConfig({ ...strategyConfig, risk_category: value });
                    }}
                  >
                    {_.map(RISK_CATRGORIES, renderOptions)}
                  </select>
                </div>

                <StrategyFormTags
                  strategyConfig={strategyConfig}
                  onUpdateStrategyConfig={setStrategyConfig}
                />

                <div className="form-group col-md-6">
                  <label className="col-form-label col-form-label-sm d-block" htmlFor="">Segment</label>
                  <div className="btn-group btn-group-toggle btn-group-sm btn-block" data-toggle="buttons">
                    {_.map(SEGMENT_NAMES, renderSegmentTypes)}
                  </div>
                </div>

                {/* second phase to show request type and free  */}
                {/* <div className="form-group col-md-6">
                  <label className="col-form-label col-form-label-sm d-block" htmlFor="">Type</label>
                  <div className="btn-group btn-group-sm btn-group-toggle btn-block" data-toggle="buttons">
                    {_.map(PURCHASE_TYPES, renderPurchaseTypes)}
                  </div>
                </div> */}

                {_.map(purchaseDetails, renderColumnDetails)}
              </div>
            </FormProvider>
          </Modal>
        )}
      {renderSuccessModal()}
    </>
  );
};

StrategyCreateForm.propTypes = propTypes;
StrategyCreateForm.defaultProps = defaultProps;

export default StrategyCreateForm;
