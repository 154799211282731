import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  runPropTypes, instrumentGroupsPropTypes, pipeConfigsPropTypes
} from 'common/propTypes';
import subscribeChannel from 'common/api/subscribeChannel';
import Modal from 'common/components/ModalComponent';
import { getUrlParams } from 'common/utils/urlUtils';
import { fetchV2JobDetails } from 'common/api/job';
import moment from 'moment-timezone';
import OpenTransactionsCard from './OpenTransactionsCard';
import MarketDataMonitorTable from './MarketDataMonitorTable';
import LiveProfitCard from './LiveProfitCard';
import { OrderContext } from '../../OrderStore/index';
import { formatV2QuantMonitorData } from './helper';

const propTypes = {
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  onOrderReceived: PropTypes.func.isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  run: runPropTypes.isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
const defaultProps = {};

// Set the time zone to IST
moment.tz.setDefault('Asia/Kolkata');

const LIVE_MONITOR_REFRESH_INTERVAL = 3 * 1000; // 3 seconds
const MARKET_START_TIME = moment('09:15:00', 'HH:mm:ss');
const MARKET_END_TIME = moment('15:30:00', 'HH:mm:ss');

const LiveMonitor = (props) => {
  const {
    instrumentGroups, run, onOrderReceived, pipeConfigs, jobIds
  } = props;
  const { showBy } = useContext(OrderContext);
  const jobId = _.head(jobIds);
  const {
    run_type: runType, status, quant_config: quantConfig, strategy_id: strategyId,
    is_strategy_subscriber: isMarketPlaceSubscribedRun, status: runStatus
  } = run;
  const oneIndentation = ' ';
  const slOrderPlaceBasedOnPrice = _.get(run, 'quant_config.slOrderPlaceBasedOnPrice', null);

  if (runType !== 'live' || status === 'killed') { return null; }
  const isMarketHrs = () => moment().isBetween(MARKET_START_TIME, MARKET_END_TIME);

  const renderMarketPlaceContent = () => {
    const urlParams = getUrlParams();
    const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';

    return (
      <div className="alert alert-sm alert-info mb-2">
        <strong>Information :</strong>
        {oneIndentation}
        Deployment Monitor showing updates for each second has been removed for Sample deployments.
        Your deployment is currently
        {oneIndentation}
        <strong>{status}</strong>
        . Go to this
        {oneIndentation}
        <a href={`/strategies/${strategyId}?locale=${I18n.locale}${isMinifiedPage
          ? '&minifiedpage=true' : ''}`}
        >
          Sample strategy
        </a>
      </div>
    );
  };

  const [tockGroups, setTockGroups] = useState([]);
  const [liveOrdersData, setLiveOrdersData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [reloadCount, setReloadCount] = useState(3);

  const LiveOrdersChannelProps = {
    roomId: `${jobId}_orders`,
    channelName: 'Live Orders',
    onDataReceive: setLiveOrdersData
  };

  const fetchV2JobMonitorDetailsPeriodically = () => {
    if (runStatus !== 'started') { return null; }
    if (!isMarketHrs()) { return null; }
    let interval = null;

    const fetchAndSetData = () => {
      if (!isMarketHrs()) {
        clearInterval(interval);
        return null;
      }

      fetchV2JobDetails(jobId)
        .then((jobDetails) => {
          const { groups } = jobDetails;
          setTockGroups(formatV2QuantMonitorData(groups));
        })
        .catch((error) => {
          console.error('Error fetching job details:', error);
        });

      return null;
    };

    fetchAndSetData();
    interval = setInterval(fetchAndSetData, LIVE_MONITOR_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (liveOrdersData.length) setIsShowModal(true); // TODO as socket
  }, [liveOrdersData]);

  useEffect(() => {
    fetchV2JobMonitorDetailsPeriodically();

    subscribeChannel(LiveOrdersChannelProps);
  }, []);

  const getOrderDataBasedOnShowBy = (idx) => {
    const data = _.get(liveOrdersData, [idx], []);

    return _.flatten([data]);
  };

  const renderLiveMonitor = (instrumentGroup, idx) => {
    const currentTockGroup = showBy === 'all' ? _.get(tockGroups, [idx], {})
      : _.get(tockGroups, [Number(showBy)], {});

    const currentLiveOrderData = showBy === 'all'
      ? getOrderDataBasedOnShowBy(idx) : getOrderDataBasedOnShowBy(Number(showBy));
    // context in orderStore show particular instrument

    const renderMarketPlaceTable = () => {
      return (
        <MarketDataMonitorTable
          tockGroup={currentTockGroup}
          instrumentGroup={instrumentGroup}
          pipeConfigs={pipeConfigs}
          headerData={[]}
        />
      );
    };

    if (_.isEmpty(quantConfig)) return null;

    return (
      <div className="col-md-6 mb-2" key={idx}>
        <div className="card live-monitor-card">
          {(slOrderPlaceBasedOnPrice !== 'signal_price')
            && <LiveProfitCard instrumentGroup={instrumentGroup} tockGroup={currentTockGroup} /> }
          <OpenTransactionsCard
            onOrderReceived={onOrderReceived}
            liveOrdersData={currentLiveOrderData}
            run={run}
          />
          {renderMarketPlaceTable()}
        </div>
      </div>
    );
  };

  const formatInstrumentGroups = () => {
    if (!showBy || showBy === 'all') return instrumentGroups;

    return _.filter(instrumentGroups, (instrument, idx) => idx === showBy || idx === Number(showBy));
  };

  useEffect(() => {
    if (!reloadCount) { window.location.reload(); }
    if (isShowModal) setTimeout(() => { setReloadCount(reloadCount - 1); }, 1000);
  }, [isShowModal, reloadCount]);

  const renderReloadModal = () => {
    return (
      <Modal size="md" shouldShow={isShowModal} backdrop="static" onClose={() => { setIsShowModal(false); }}>
        <div className="text-center">
          <h4>New Order Received</h4>
          <img src="/new-order.png" alt="user-alert" className="alert-modal-img my-4" />
          <p>New Order Received for the strategy. Please wait a moment, the page will be reloaded.</p>
          <p><b className="tx-25 my-2">{reloadCount || ''}</b></p>
          <button
            type="button"
            onClick={() => window.location.reload()}
            className="btn btn-outline-primary btn-pill"
          >
            Reload
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {isMarketPlaceSubscribedRun && renderMarketPlaceContent()}
      {renderReloadModal()}
      <div className="live-run">
        <div className="row mt-3">{_.map(formatInstrumentGroups(), renderLiveMonitor)}</div>
      </div>
    </>
  );
};

LiveMonitor.defaultProps = defaultProps;
LiveMonitor.propTypes = propTypes;

export default LiveMonitor;
