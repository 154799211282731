import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Quantity from 'common/components/Quantity';
import { useBasketIntent } from 'v2/modules/withRunForm/hooks/useIntent/useBasketIntent';
import { intentPropTypes } from 'common/propTypes';
import SegmentSelector from 'v2/common/components/SegmentSelector/index';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';
import classNames from 'classnames';
import BasketSelector from './BasketSelector';
import IntentTypeSelector from '../IntentTypeSelector';

const propTypes = {
  intent: intentPropTypes.isRequired,
  renderDeleteBtn: PropTypes.element,
};

const defaultProps = {
  renderDeleteBtn: null
};

const BasketIntent = ({
  intent, renderDeleteBtn
}) => {
  const intentConfigs = useBasketIntent(intent);
  const {
    control,
    errors,
    isAdjustmentIntent
  } = useContext(IntentsContext);

  const instrumentClassNames = classNames('transaction_details-items', {
    instrument: !intentConfigs?.isShowInstrumentIndex,
    pair_instrument: intentConfigs?.isShowInstrumentIndex,
  });

  return (
    <div className="transaction_details body">
      <span className="leg-label-ribbon basket">
        <div className="wrapper">
          <div className="badge">
            Basket
            {' '}
            {intentConfigs?.intentId}
          </div>
        </div>
      </span>
      <IntentTypeSelector
        isAdjustmentIntent={isAdjustmentIntent}
        isAdvancedMode={intentConfigs.isAdvancedMode}
        value={intent.type}
        onChange={intentConfigs.onChangeIntentType}
      />
      <div className="transaction_details-items segment">
        <SegmentSelector
          segment={intentConfigs?.segment}
          segmentOptionConfigs={intentConfigs?.segmentOptionConfigs}
          onChangeSegment={intentConfigs?.onChangeSegment}
        />
      </div>
      <div className={instrumentClassNames}>
        <BasketSelector
          name={intentConfigs?.name || ''}
          onChangeName={intentConfigs?.onChangeBasketName}
        />
      </div>
      <div className="transaction_details-items quantity">
        <Quantity
          label="Multiplier"
          quantity={intentConfigs?.multiplier}
          onChangeQuantity={intentConfigs.onChangeMultiplier}
          control={control}
          errors={errors}
        />
      </div>
      {renderDeleteBtn && renderDeleteBtn}
    </div>
  );
};

BasketIntent.propTypes = propTypes;
BasketIntent.defaultProps = defaultProps;

export default BasketIntent;
